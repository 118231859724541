import React, {useState, useEffect, useContext} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Link from 'next/link'
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function DealOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  let daysStatus = props.data.offer_days;
  if (props.data.offer_days.indexOf('sun') > -1 &&
      props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1 &&
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'All Days'
    }else if(props.data.offer_days.indexOf('sun') > -1 &&    
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'Week Ends'
    }else if(props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1) {
        daysStatus = 'Week Days'
    }


  const DealCardFive = {
    background: theme.palette.background.alternate,
    border:`1px solid ${theme.palette.background.borderColor}`,
    borderRadius:5,
    boxShadow:theme.shadows[0],
    transition:`0.5s`,
    mb:2,
    '&:hover':{
      background: theme.palette.background.alternate,
      transition:`0.5s`,
      boxShadow: theme.shadows[4]
    },
    '& h3':{
      fontFamily:themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:2,
      textTransform:'capitalize',
    },
    '& h4':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:20,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
      pb:`0px !important`,
    },
    '& h5':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:1,
    },
    '& h6':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      display:'flex',
      alignItems:'center'
    }
}

const ButtonStyleFive = {
  '& a':{
    fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
    borderRadius:10,
    background:themeCss ? themeCss.theme_main_color: ``,
    color: theme.palette.primary.contrastText,
    textDecoration:`none`,
    cursor:'pointer',
    fontSize: 14,
    fontWeight:500,
    p:1,
    display:'flex',
    alignItems:'center',
    mb:1,
    '&:hover':{
      background:themeCss ? themeCss.theme_main_color: ``,
      color: theme.palette.primary.contrastText,
      textDecoration:`none`,
      opacity:0.9,
    }
  }
};

const date = {
  fontSize: 14,
  fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
  display: 'inline-block',  
  fontWeight:900
};

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

const DEAL_TITLE_MAX_LENGTH = 25;

  return (
    <Link href={`/deal/`+props.data.slug+`/`+props.data.id}>
      <Card sx={DealCardFive}>
        <Stack display={'flex'} flexDirection={'row'}>
            <CardMedia
                component="img"
                sx={{ width: 80, borderRadius:0}}
                image={props.data.img_path}
                alt={props.data.title}
            />          
            <CardContent sx={{width:'100%'}}>
              <Typography variant="h3">
                {(props.data.title.length >= DEAL_TITLE_MAX_LENGTH
                  ? (props.data.title.toLowerCase()).substring(0, DEAL_TITLE_MAX_LENGTH) +
                    "..."
                  : props.data.title.toLowerCase()
                ).toLowerCase()}
              </Typography>
              <Stack display={'flex'} flexDirection={{md:'row', sm:'column'}} justifyContent={'space-between'}>
              {props.data.days_type == 's' ? (
                <>
                  <Typography variant="h6" sx={date}>                                               
                    <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                    {props.data.end_time.substring(0,5)}
                  </Typography>
                  <Typography variant="h6" sx={date}>   
                  <CalendarMonthIcon sx={iconStyle} />{daysStatus}
                  </Typography>
                </>
                ):(
                  <>
                  <Typography variant="h6" sx={date}>                                                   
                    <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                    {props.data.end_time.substring(0,5)}
                  </Typography>
                  <Typography variant="h6" sx={date}>   
                    {daysStatus == 'All Days' || daysStatus == 'Week Days' || daysStatus == 'Week Ends' ? (
                      <><CalendarMonthIcon sx={iconStyle} />{daysStatus}</>
                    ):(
                      <>
                      {daysStatus.split(",").map((item, i) => (
                        <span >
                            {item}
                            {i < daysStatus.split(",").length - 1 ? "," : ""}{" "}
                        </span>
                      ))}
                      </>
                    )}                          
                  </Typography>
                </>
              )}
              <Typography variant="h6" sx={{ml:5}}>&nbsp;</Typography>
              </Stack>
              <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{mt:2}}>
                  <Typography component="div" alignSelf={'center'}>
                      <Typography variant="h4"><strike><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.price}</strike></Typography>
                  </Typography>
                  <Typography component="div" alignSelf={'center'}>
                      <Typography variant="h4" sx={{mb:`0px !important`}}><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.actual_price}</Typography>
                  </Typography>
                  <Typography component="div" alignSelf={'center'} align='center'>
                    <Typography component="div" sx={ButtonStyleFive} >
                      <Link href={`/deal/`+props.data.slug+`/`+props.data.id}><ArrowForwardIcon /></Link>
                    </Typography>
                  </Typography>
              </Stack>
            </CardContent>
        </Stack>
      </Card>
    </Link>
  );
}