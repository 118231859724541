import React, {useState, useEffect, useContext} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ButtonTypeFour from '../../components/buttons/TypeFour'
import Link from 'next/link';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function DealTwo(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  let daysStatus = props.data.offer_days;
  if (props.data.offer_days.indexOf('sun') > -1 &&
      props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1 &&
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'All Days'
    }else if(props.data.offer_days.indexOf('sun') > -1 &&    
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'Week Ends'
    }else if(props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1) {
        daysStatus = 'Week Days'
    }

  const dealCardTwo = {
    background: theme.palette.background.alternate,
    p:2,
    border:`1px solid ${theme.palette.background.borderColor}`,
    borderRadius:5,
    boxShadow:theme.shadows[0],
    "&:hover":{
      boxShadow:theme.shadows[2],
    },
    mb:1,
    '& h3':{
      fontFamily:themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:1,
      textTransform:'capitalize',
    },
    '& h4':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:20,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
      pb:`0px !important`,
    },
    '& h5':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:2,
    },
    '& h6':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.location,
      display:'flex',
      alignItems:'center'
    }
}

const date = {
  fontSize: 14,
  fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
  // color: `${themeCss && themeCss.theme_primary_font_color} !important`,
  display: 'inline-block',  
  fontWeight:900
};

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

const DEAL_TITLE_MAX_LENGTH = 25;
  return (
  <Link href={`/deal/`+props.data.slug+`/`+props.data.id}>
    <Grid container spacing={0} align="right">
      <Grid item xs="12" alignSelf={'baseline'}>
        <CardMedia
          component="img"
          sx={{ width: 120, height:120, borderRadius:4, marginBottom:-10, marginRight:2,border:`1px solid #5D5D5D`}}
          image={props.data.img_path}
          alt={props.data.title}
        />
      </Grid>
    </Grid>

    <Card sx={dealCardTwo} boxShadow={6}>
        <Grid container spacing={0} alignItems={'flex-start'}>
          <Grid item xs="8" alignSelf={'baseline'}>
            <Typography variant="h3">
              {(props.data.title.length >= DEAL_TITLE_MAX_LENGTH
                ? (props.data.title.toLowerCase()).substring(0, DEAL_TITLE_MAX_LENGTH) +
                  "..."
                : props.data.title.toLowerCase()
              ).toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs="12" alignSelf={'baseline'}>
            <CardContent sx={{p:0, pb:`0px !important`, mt:1.5}}>
              <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                {props.data.days_type == 's' ? (
                <>
                  <Typography variant="h6" sx={date}>                                               
                    <CalendarMonthIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                    {props.data.end_time.substring(0,5)}
                  </Typography>
                  <Typography variant="h6" sx={date}>   
                    <CalendarMonthIcon sx={iconStyle} />{daysStatus}
                  </Typography>
                </>
                ):(
                  <>
                    <Typography variant="h6" sx={date}>                                                   
                      <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                      {props.data.end_time.substring(0,5)}
                    </Typography>
                    <Typography variant="h6" sx={date}>   
                        {daysStatus == 'All Days' || daysStatus == 'Week Days' || daysStatus == 'Week Ends' ? (
                          <><CalendarMonthIcon sx={iconStyle} />{daysStatus}</>
                        ):(
                          <>
                          {daysStatus.split(",").map((item, i) => (
                            <span >
                                {item}
                                {i < daysStatus.split(",").length - 1 ? "," : ""}{" "}
                            </span>
                          ))}
                          </>
                        )}                          
                      </Typography>
                    </>
                )}
              <Typography variant="h6" sx={{ml:17}}>&nbsp;</Typography>
              </Stack>
              <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'baseline'} sx={{mt:1.5}}>
                  <Typography component="div" alignSelf={'baseline'}>
                      <Typography variant="h4" sx={{mb:`0px !important`}}><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.actual_price}</Typography>
                  </Typography>
                  <Typography component="div" alignSelf={'baseline'}>
                      <Typography variant="h4"><strike><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.price}</strike></Typography>
                  </Typography>
                  <Typography component="div" alignSelf={'center'} align='center'>
                    <ButtonTypeFour text="Buy" link={`/deal/`+props.data.slug+`/`+props.data.id} sx={{display:'block'}} />
                  </Typography>
              </Stack>
            </CardContent>
          </Grid>
        </Grid>
    </Card>
  </Link>
  );
}