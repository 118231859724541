import React, {useState, useEffect} from 'react';
import Link from 'next/link'
import { Typography } from '@mui/material';
import Button from '@mui/material/Button'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

function TypeOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

  const [loading, setLoading] = useState(true)
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
      let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
      setThemeCss(custom_css);
  }, [themeData]);  

  const ButtonStyleFour = {
    '& a':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius:5,
      background:themeCss ? themeCss.theme_main_color: ``,
      color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
      textDecoration:`none`,
      minWidth:100,
      fontSize: 14,
      fontWeight:500,
      py:1,
      px:3,
      '&:hover':{
        background:themeCss ? themeCss.theme_main_color: ``,
        color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
        textDecoration:`none`,
        opacity:0.9,
      }
    },
    '& button':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius:5,
      background:themeCss ? themeCss.theme_main_color: ``,
      color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
      textDecoration:`none`,
      width:'100%',
      fontSize: 14,
      fontWeight:500,
      py:1,
      px:3,
      '&:hover':{
        background:themeCss ? themeCss.theme_main_color: ``,
        color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
        textDecoration:`none`,
        opacity:0.9,
      }
    }
};

  return (
    <>
      {props.link != '' ? (
        <Typography component="div" sx={ButtonStyleFour} >
          <Link href={props.link} variant="contained" style={{...props.sx}}>{props.text}</Link>
        </Typography>    
      ):(
        <Typography component="div" sx={ButtonStyleFour} >
          <Button type="submit" variant="contained" style={{...props.sx}}>{props.text}</Button>
        </Typography>  
      )}
    </>
  )
}

export default TypeOne