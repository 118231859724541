import React, {useState, useEffect} from 'react';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Link from 'next/link'
import { Button, Typography } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


function TypeOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

  const [loading, setLoading] = useState(true)
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
      let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
      setThemeCss(custom_css);
  }, [themeData]);
  

  const ButtonStyleOne = {
    '& button':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius: props.borderRadius ? props.borderRadius : 0,
      background:themeCss ? themeCss.theme_main_color: ``,
      color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
      textDecoration:`none`,
      minWidth:100,
      fontSize: 14,
      fontWeight:500,
      px:2.5,
      py:1,

      '&:hover':{
        background:themeCss ? themeCss.theme_main_color: ``,
        color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
        textDecoration:`none`,
        opacity:0.9,
      }
    },
    '& a':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius: props.borderRadius ? props.borderRadius : 0,
      background:themeCss ? themeCss.theme_main_color: ``,
      color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
      textDecoration:`none`,
      minWidth:100,
      fontSize: 14,
      fontWeight:500,
      px:2.5,
      py:1.5,

      '&:hover':{
        background:themeCss ? themeCss.theme_main_color: ``,
        color: themeCss ? themeCss.theme_primary_font_color: `#151414`,
        textDecoration:`none`,
        opacity:0.9,
      }
    }
};

  return (
    <Typography component="div" sx={ButtonStyleOne} >
      {props.link ? (
        <Link href={props.link} variant="contained" >
          <>
            {props.text}
            <CallMadeIcon style={{marginLeft:'15px', fontSize:14}} />
          </>
        </Link>
      ):(
        <Button type="submit" variant="contained" sx={{...props.sx}}>
        <>
          {props.text}
          <CallMadeIcon style={{marginLeft:'15px', fontSize:14}} />
        </>
        </Button>
      )}
    </Typography>    
  )
}

export default TypeOne