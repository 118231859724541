import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import ButtonTypeFour from '../../components/buttons/TypeFour'
import Link from 'next/link';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function EventTwo(props) {

  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  let daysStatus = props.data.offer_days;
  if (props.data.offer_days.indexOf('sun') > -1 &&
      props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1 &&
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'All Days'
    }else if(props.data.offer_days.indexOf('sun') > -1 &&    
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'Week Ends'
    }else if(props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1) {
        daysStatus = 'Week Days'
    }

  const DealCardFour = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:'transparent',
    boxShadow:0,
    borderRadius:0,
    mb:0,
    transition:`0.5s`,
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:2,
      textTransform:'capitalize',
    },
    '& h4':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ")+" !important" : '',
      fontSize:20,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
      pb:`0px !important`,
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:1,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      display:'flex',
      alignItems:'center'
    },
    '& .cardContent':{
      p:2, 
      borderRadius:4, 
      background:theme.palette.background.default, 
      border:`1px solid ${theme.palette.background.borderColor}`, 
      position:'relative', 
      width:'95%',
      marginLeft:'2.5%',
      marginRight:'2.5%',
      marginTop:-7.5,
      mb:4,
      boxShadow:theme.shadows[0],
      transition:`0.5s`,
    },
    '&:hover':{
      transition:`0.5s`,
      '& .cardContent':{
        background: theme.palette.background.alternate,
        border: `1px solid transparent`,
        boxShadow:theme.shadows[2]
      }
    }
}

const date = {
  fontSize: 14,
  fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
  display: 'inline-block',  
  fontWeight:900
};

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

  const DEAL_TITLE_MAX_LENGTH = 25;
  
  return (
    <Link href={`/deal/`+props.data.slug+`/`+props.data.id}>
      <Card sx={{...DealCardFour, borderRadius:5, background:'none'}} elevation={0}>
        <CardMedia
          component="img"
          height="350"
          image={props.data.img_path}
          alt={props.data.title}
          sx={{borderRadius:5}}
        />
        <CardContent sx={{ p:1}}>
        <Typography component="div" className={`cardContent`}>
          <Typography variant="h3">
            {(props.data.title.length >= DEAL_TITLE_MAX_LENGTH
              ? (props.data.title.toLowerCase()).substring(0, DEAL_TITLE_MAX_LENGTH) +
                "..."
              : props.data.title.toLowerCase()
            ).toLowerCase()}
          </Typography>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          {props.data.days_type == 's' ? (
              <>
                <Typography variant="h6" sx={date}>                                               
                <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                  {props.data.end_time.substring(0,5)}
                </Typography>
                <Typography variant="h6" sx={date}> 
                  <CalendarMonthIcon sx={iconStyle} />{daysStatus}
                </Typography>
              </>
            ):(
              <>
              <Typography variant="h6" sx={date}>                                                   
                  <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                  {props.data.end_time.substring(0,5)}
              </Typography>
              <Typography variant="h6" sx={date}> 
                  {daysStatus == 'All Days' || daysStatus == 'Week Days' || daysStatus == 'Week Ends' ? (
                    <><CalendarMonthIcon sx={iconStyle} />{daysStatus}</>
                  ):(
                    <>
                    {daysStatus.split(",").map((item, i) => (
                      <span >
                          {item}
                          {i < daysStatus.split(",").length - 1 ? "," : ""}{" "}
                      </span>
                    ))}
                    </>
                  )}                          
                </Typography>
              </>
            )}
          </Stack>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{mt:3}}>
            <Typography component="div" sx={{display:'flex', alignSelf:'center'}}>
                <Typography variant="h4" sx={{mb:`0px !important`,minHeight:'0px !important'}}><strike><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.price}</strike></Typography>
            </Typography>
            <Typography component="div" sx={{display:'flex', alignSelf:'center'}}>
                <Typography variant="h4" sx={{mb:`0px !important`,minHeight:'0px !important', fontWeight:`500 !important`}}><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.actual_price}</Typography>
            </Typography>
            <Typography component="div" sx={{display:'flex', alignSelf:'center'}} align='center'>
              <ButtonTypeFour text="Buy" link="" />
            </Typography>
        </Stack>
        </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}