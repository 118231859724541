import React, {useState, useEffect, useContext} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ButtonTypeFour from '../../components/buttons/TypeFour'
import Image from 'next/image'
import CalendarIcon from '../../public/images/icons/Calendar.png'
import TimerIcon from '../../public/images/icons/Timer.png'
import Link from 'next/link'
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


export default function DealSix(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  let daysStatus = props.data.offer_days;
  if (props.data.offer_days.indexOf('sun') > -1 &&
      props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1 &&
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'All Days'
    }else if(props.data.offer_days.indexOf('sun') > -1 &&    
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'Week Ends'
    }else if(props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1) {
        daysStatus = 'Week Days'
    }

  const dealCardSix = {
    background: theme.palette.background.default,
    p:2,
    borderRadius:5,
    mb:0, 
    border:`1px solid ${theme.palette.background.borderColor}`,
    boxShadow: theme.shadows[0],
    transition: `0.5s`,
    "&:hover":{
      background: theme.palette.background.alternate,
      boxShadow: theme.shadows[3],
      transition: `0.5s`,
    },
    '& h3':{
      fontFamily:themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:{md:2, sm:1, xs:1},
      textTransform:'capitalize',
    },
    '& h4':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:20,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
      pb:`0px !important`,
    },
    '& h5':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:16,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
    },
    '& h6':{
      fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.location,
      display:'flex',
      alignItems:'center'
    }
}

const date = {
  fontSize: 14,
  fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
  display: 'inline-block',  
  fontWeight:900
};

const imgStyle = {
  '& img':{
    minWidth:'110px',
    maxHeight:'100px',
  }
}

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

const DEAL_TITLE_MAX_LENGTH = 35;
  return (
    <Link href={`/deal/`+props.data.slug+`/`+props.data.id}>
      <Card sx={dealCardSix} boxShadow={6}>
          <Grid container spacing={0} alignItems={'flex-start'}>
            <Grid item xs="12">
              <Typography variant="h3">
                {(props.data.title.length >= DEAL_TITLE_MAX_LENGTH
                  ? (props.data.title.toLowerCase()).substring(0, DEAL_TITLE_MAX_LENGTH) +
                    "..."
                  : props.data.title.toLowerCase()
                ).toLowerCase()}
              </Typography>
            </Grid>
            <Grid item xs="3" alignSelf={'center'} align={'left'} sx={{...imgStyle}}>
                <CardMedia
                    component="img"
                    sx={{ width: 100, height:100, borderRadius:4, border:`1px solid #5D5D5D`}}
                    image={props.data.img_path}
                    alt={props.data.title}
                />
            </Grid>
            <Grid item xs="9" alignSelf={'center'}>
              <CardContent sx={{px:{md:0, sm:4, xs:4}, pb:`0 !important`}}>
                <Stack display={'flex'} flexDirection={{md:'row', sm:'column'}} justifyContent={'space-between'} alignSelf={'center'}>
                {props.data.days_type == 's' ? (
                  <>
                    <Typography variant="h6" sx={date}>                                               
                      <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                      {props.data.end_time.substring(0,5)}
                    </Typography>
                    <Typography variant="h6" sx={date}>   
                      <AccessTimeIcon sx={iconStyle} />{daysStatus}
                    </Typography>
                    <Typography component="span">&nbsp;</Typography>
                  </>
                  ):(
                    <>
                      <Typography variant="h6" sx={date}>                                                   
                        <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                        {props.data.end_time.substring(0,5)}
                      </Typography>
                      <Typography variant="h6" sx={date}>   
                        {daysStatus == 'All Days' || daysStatus == 'Week Days' || daysStatus == 'Week Ends' ? (
                          <><CalendarMonthIcon sx={iconStyle} />{daysStatus}</>
                        ):(
                          <>
                          {daysStatus.split(",").map((item, i) => (
                            <span >
                                {item}
                                {i < daysStatus.split(",").length - 1 ? "," : ""}{" "}
                            </span>
                          ))}
                          </>
                        )}                          
                      </Typography>
                      <Typography component="span">&nbsp;</Typography>
                    </>
                )}
              </Stack>
                <Stack display={'flex'} flexDirection={{md:'row', sm:'column'}} justifyContent={'space-between'} sx={{mt:2}} alignSelf={'center'}>
                    <Typography component="div" display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Typography variant="h4" sx={{mb:2}}><strike><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.price}</strike></Typography>
                        <Typography variant="h4" className="mobileMode" sx={{mb:`0px !important`}}><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.actual_price}</Typography>
                    </Typography>
                    <Typography component="div">
                        <Typography variant="h4" className="desktopMode" sx={{mb:`0px !important`}}><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.actual_price}</Typography>
                    </Typography>
                    <Typography component="div">
                      <ButtonTypeFour text="Buy" link="" />
                    </Typography>
                </Stack>
              </CardContent>
            </Grid>
          </Grid>
      </Card>
    </Link>
  );
}