import React, {useState, useEffect, useContext} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid, Stack } from '@mui/material';
import ButtonTypeOne from '../../components/buttons/TypeOne'
import Link from 'next/link'
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function DealOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  let daysStatus = props.data.offer_days;
  if (props.data.offer_days.indexOf('sun') > -1 &&
      props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1 &&
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'All Days'
    }else if(props.data.offer_days.indexOf('sun') > -1 &&    
      props.data.offer_days.indexOf('sat') > -1) {
        daysStatus = 'Week Ends'
    }else if(props.data.offer_days.indexOf('mon') > -1 &&
      props.data.offer_days.indexOf('tue') > -1 &&
      props.data.offer_days.indexOf('wed') > -1 &&
      props.data.offer_days.indexOf('thu') > -1 &&
      props.data.offer_days.indexOf('fri') > -1) {
        daysStatus = 'Week Days'
    }

  const dealCardOne = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:'transparent',
    borderRadius:0,
    mb:2,
    border:`1px solid ${theme.palette.background.borderColor}`,
    boxShadow:theme.shadows[0],
    '& .cardContent':{
      transition:`0.5s`,
      px:1
    },
    "&:hover":{
      boxShadow:theme.shadows[3],
      background: theme.palette.background.alternate,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:1,
      textTransform:'capitalize',
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:1,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.location,
      display:'flex',
      alignItems:'center'
    }
}

const actionArea = {
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:28,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:0,
      textTransform:'capitalize',
      minHeight:0,
    },
    '& h4':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:16,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:0,
      pb:`0px !important`,
      display:'flex',
      alignItems:'center'
    },
}
const date = {
  fontSize: 14,
  fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
  // color: `${themeCss && themeCss.theme_primary_font_color} !important`,
  display: 'inline-block',  
  fontWeight:900
};

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}
  
const DEAL_TITLE_MAX_LENGTH = 20;

  return (
    <Link href={`/deal/`+props.data.slug+'/'+props.data.id}>
      <Card sx={dealCardOne} raised={true}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="250"
            image={props.data.img_path}
            alt={props.data.title}
            sx={{borderRadius:0}}
          />
      
          <CardContent className='cardContent'>
              <Typography variant="h3">
              {(props.data.title.length >= DEAL_TITLE_MAX_LENGTH
                  ? (props.data.title.toLowerCase()).substring(0, DEAL_TITLE_MAX_LENGTH) +
                  "..."
                  : props.data.title.toLowerCase()
              ).toLowerCase()}
              </Typography>
              <Stack flexDirection="row" justifyContent={'space-between'}>
                {props.data.days_type == 's' ? (
                  <>
                    <Typography variant="h6" sx={date}>                                               
                      <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                      {props.data.end_time.substring(0,5)}
                    </Typography>
                    <Typography variant="h6" sx={date}>   
                    <CalendarMonthIcon sx={iconStyle} />{daysStatus}
                    </Typography>
                  </>
                  ):(
                    <>
                      <Typography variant="h6" sx={date}>                                                   
                        <AccessTimeIcon sx={iconStyle} />{props.data.start_time.substring(0,5)}&nbsp;-&nbsp;
                        {props.data.end_time.substring(0,5)}
                      </Typography>
                      <Typography variant="h6" sx={date}>   
                        {daysStatus == 'All Days' || daysStatus == 'Week Days' || daysStatus == 'Week Ends' ? (
                          <><CalendarMonthIcon sx={iconStyle} />{daysStatus}</>
                        ):(
                          <>
                          {daysStatus.split(",").map((item, i) => (
                            <span >
                                {item}
                                {i < daysStatus.split(",").length - 1 ? "," : ""}{" "}
                            </span>
                          ))}
                          </>
                        )}                          
                      </Typography>
                    </>
                  )}
                  <Typography component="span">&nbsp;</Typography>
              </Stack>
              <Grid container sx={{mt:2, ...actionArea}}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} alignSelf={"end"}>
                      <Typography variant="h4"><strike><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.price}</strike></Typography>
                      <Typography variant="h4" sx={{mt:1, mb:`0px !important`, fontWeight:`700 !important`}}><CurrencyRupeeIcon sx={{fontSize:14, mr:0.5}}/>{props.data.actual_price}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} alignSelf={"end"} textAlign={'right'}>
                      <ButtonTypeOne text="Buy Now" link={'/event/'+props.data.slug+'/'+props.data.id} />
                  </Grid>
              </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}


